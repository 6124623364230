import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'short_code'
})

export default {
    namespaced: true,
    state: {
        filter: {}
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async assign(context, params) {
            return ApiHelper.post('/api/short-code/assign', params);
        },
        async fetchSettings(context, params) {
            return ApiHelper.get('/api/short-code/settings', params);
        },
        async saveSettings(context, payload) {
            return ApiHelper.post('/api/short-code/settings', payload);
        }
    },
    getters: {}
}