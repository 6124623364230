import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'version'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {
            platform: '',
            current: false,

        },
        platforms: [{
            text: 'Windows',
            value: 'windows'
        },{
            text: 'Linux',
            value: 'linux'
        }]
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {
        ...crud.getters
    }
}