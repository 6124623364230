import Login from '@/views/Login.vue'
import Activation from '@/views/Activation.vue'
import Error404 from '@/views/Error404.vue'

import crud from './crud'
import breadcrumbs from './breadcrumbs'

export default [{
        path: "/",
        redirect: "/order"
    },{
        path: '/activation',
        name: 'Activation',
        component: Activation,
        meta: {
            layout: 'login'
        }
    },{
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'login'
        }
    },
    ..._.flatten(_.map(crud, (item) => {
        return [{
                path: `/${item.module}`,
                component: () => import(`@/views/${item.module}/Index.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].index
                }
            },
            {
                path: `/${item.module}/create`,
                component: () => import(`@/views/${item.module}/Create.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].create
                }
            },
            {
                path: `/${item.module}/update/:id`,
                component: () => import(`@/views/${item.module}/Update.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].update
                }
            }
        ];
    })),
    {
        path: `/short_code/assign`,
        component: () => import(`@/views/short_code/Assign.vue`),
        meta: {
            breadcrumbs: breadcrumbs['short_code'].assign
        }
    },
    {
        path: `/short_code/settings`,
        component: () => import(`@/views/short_code/Settings.vue`),
        meta: {
            breadcrumbs: breadcrumbs['short_code'].settings
        }
    },
    {
        path: '*',
        component: Error404,
        meta: {
            breadcrumbs: [{
                    text: 'Главная',
                    disabled: false,
                    href: '/'
                },
                {
                    text: 'Страница не найдена',
                    disabled: true
                }
            ]
        }
    }
]